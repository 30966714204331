import React, { useState, useEffect } from 'react';
import {Button, Form, Row, Col, Alert} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import './Awn3.css';
import { animateScroll as scroll } from 'react-scroll';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


function Awn1() {
    const [submissionDate, setSubmissionDate] = useState(new Date());
    const [jobNumber, setJobNumber] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectType, setProjectType] = useState('');
    const [researcherCharge, setResearcherCharge] = useState('');
    const [researcherCost, setResearcherCost] = useState('');
    const [finalQuestionnaire, setFinalQuestionnaire] = useState('');
    const [finalQuestionnaireChanged, setFinalQuestionnaireChanged] = useState(false);
    const [scirptingNeeded, setScirptingNeeded] = useState('');
    const [complexLogic, setComplexLogic] = useState('');
    const [sampleSize, setSampleSize] = useState('');
    const [dataCollection, setDataCollection] = useState('');
    const [haveS2CSub, setHaveS2CSub] = useState('');
    const [quota, setQuota] = useState('');
    const [quotaDetails, setQuotaDetails] = useState('');
    const [quotaDetailsChanged, setQuotaDetailsChanged] = useState('');
    const [expectedFieldDate, setExpectedFieldDate] = useState('');
    const [expectedFieldDateChanged, setExpectedFieldDateChanged] = useState('');
    const [analysisPlan, setAnalysisPlan] = useState('');
    const [analysisPlanChanged, setAnalysisPlanChanged] = useState('');
    const [firstCutDate, setFirstCutDate] = useState('');
    const [firstCutDateChanged, setFirstCutDateChanged] = useState('');
    const [dataWeightingRequired, setDataWeightingRequired] = useState('');
    const [sigTesting, setSigTesting] = useState('');
    const [spssOutput, setSpssOutput] = useState('');
    const [finalOutput, setFinalOutput] = useState('');
    const [finalOutputChanged, setFinalOutputChanged] = useState('');
    const [weightingMatrix, setWeightingMatrix] = useState('');
    const [weightingMatrixChanged, setWeightingMatrixChanged] = useState('');
    const [option1, setOption1] = useState(false);
    const [option2, setOption2] = useState(false);
    const [option3, setOption3] = useState(false);
    const [option4, setOption4] = useState(false);
    const [statementFF, setStatementFF] = useState('');
    const [maxdiffProgramming, setMaxdiffProgramming] = useState('');
    const [jaccards, setJaccards] = useState('');
    const [regression, setRegression] = useState('');
    const [krushal, setKrushal] = useState('');
    const [shapely, setShapely] = useState('');
    const [correlation, setCorrelation] = useState('');
    const [chaid, setChaid] = useState('');
    const [cluster, setCluster] = useState('');
    const [factor, setFactor] = useState('');
    const [bIP, setBIP] = useState('');
    const [dDV, setDDV] = useState('');
    const [dIV, setDIV] = useState('');
    const [numberOfAttributes, setNumberOfAttributes] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [showNext2, setShowNext2] = useState(false);
    const navigate = useNavigate();

    const handleQuotaDetailsChange = (date) => {
        setQuotaDetails(date);
        setQuotaDetailsChanged(true);
      };

      const handleExpectedFieldChange = (date1) => {
        setExpectedFieldDate(date1);
        setExpectedFieldDateChanged(true);
      };

      const handleAnalysisChange = (date2) => {
        setAnalysisPlan(date2);
        setAnalysisPlanChanged(true);
      };

      const handleFirstCutChange = (date3) => {
        setFirstCutDate(date3);
        setFirstCutDateChanged(true);
      };

      const handleFinalOutputChange = (date4) => {
        setFinalOutput(date4);
        setFinalOutputChanged(true);
      };

      const handleWeightingMatrixChange = (date5) => {
        setWeightingMatrix(date5);
        setWeightingMatrixChanged(true);
      };

      const handleFinalQuestionnaireChange = (date6) => {
        setFinalQuestionnaire(date6);
        setFinalQuestionnaireChanged(true);
      };

    const handleSampleSizeChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setSampleSize(value);
        }
    };

    const handleNoaChange = (e) => {
        const value1 = e.target.value;
        if (/^\d*$/.test(value1)) {
            setNumberOfAttributes(value1);
        }
    };

    const handleNext1 = () => {
        if(submissionDate && jobNumber && projectName && projectType && researcherCharge && researcherCost &&
            finalQuestionnaireChanged &&  scirptingNeeded && sampleSize && dataCollection  &&  complexLogic
             && dataCollection
        ) {
            setShowAlert(false);
            setShowNext(true);
            scroll.scrollToBottom({
                duration: 1,
                delay: 0,
                smooth: 'easeInOutQuart',
              });
        }
        else{
            setShowAlert(true);
        }        
    };

    const handleNext2 = () => {
        if(firstCutDateChanged && finalOutputChanged 
             && statementFF && 
            spssOutput  && haveS2CSub &&
         expectedFieldDateChanged && analysisPlanChanged && (quota!== "Yes" || quotaDetails) &&
        (dataWeightingRequired!== "Yes" || weightingMatrix ) &&
         (sigTesting !== "Yes" || (option1 || option2 || option3 || option4)) ) {
            setShowNext2(true);
            setShowAlert2(false);
            scroll.scrollToBottom({
                duration: 1,
                delay: 0,
                smooth: 'easeInOutQuart',
              });
        }
        else{
            setShowAlert2(true);
        }        
    };

    const handleDownload = async () => {
        if ((maxdiffProgramming!== "Yes" || numberOfAttributes) && jaccards && shapely && correlation && chaid
         && cluster && factor && bIP &&
            regression && krushal &&  submissionDate && jobNumber && projectName &&
             projectType && researcherCharge && researcherCost &&
            finalQuestionnaire &&  scirptingNeeded && sampleSize && dataCollection  &&  complexLogic
             && dataCollection && haveS2CSub && firstCutDate && dataWeightingRequired && sigTesting && finalOutput &&
             statementFF && (quota!== "Yes" || quotaDetails) && (dataWeightingRequired!== "Yes" || weightingMatrix ) &&
             (sigTesting !== "Yes" || (option1 || option2 || option3 || option4)) &&
             spssOutput  && 
          expectedFieldDate && analysisPlan 
        ){
        setShowAlert3(false);
        const response = await fetch('/4SiGHT_Analysis Warning Note.xlsx');
        const arrayBuffer = await response.arrayBuffer();
        const workbook = new Workbook();
        await workbook.xlsx.load(arrayBuffer);
        const worksheet = workbook.worksheets[0];

        const selectedOptions = [];
        if (option1) selectedOptions.push('☑95%');
        if (option2) selectedOptions.push('☑90%');
        if (option3) selectedOptions.push('☑80%');
        if (option4) selectedOptions.push('☑67%');        
        const selectedOptionsString = selectedOptions.join(', ');
        const formatDate = (date) => {
            if (!date) return '';
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return new Date(date).toLocaleDateString('en-GB', options);
        };

        const setCellValueAndAlignRight = (cell, value) => {
            worksheet.getCell(cell).value = value;
            worksheet.getCell(cell).alignment = { horizontal: 'right' };
        };

        worksheet.getCell('C6').value = submissionDate;
        worksheet.getCell('C7').value = jobNumber;
        worksheet.getCell('C8').value = projectName;
        worksheet.getCell('C9').value = projectType;
        worksheet.getCell('C10').value = researcherCharge;
        worksheet.getCell('C11').value = researcherCost;
        setCellValueAndAlignRight('C14', formatDate(finalQuestionnaire));
        worksheet.getCell('C15').value = scirptingNeeded;
        worksheet.getCell('F15').value = complexLogic;
        worksheet.getCell('C16').value = sampleSize;
        worksheet.getCell('C17').value = dataCollection;
        worksheet.getCell('C18').value = haveS2CSub;
        setCellValueAndAlignRight('C26', formatDate(firstCutDate));
        worksheet.getCell('C28').value = dataWeightingRequired;
        worksheet.getCell('C30').value = sigTesting;
        worksheet.getCell('C32').value = spssOutput;
        setCellValueAndAlignRight('F26', formatDate(finalOutput));
        setCellValueAndAlignRight('F28', formatDate(weightingMatrix));
        worksheet.getCell('F32').value = statementFF;
        worksheet.getCell('C19').value = quota;
        setCellValueAndAlignRight('C20', formatDate(quotaDetails));
        setCellValueAndAlignRight('C21', formatDate(expectedFieldDate));
        setCellValueAndAlignRight('C22', formatDate(analysisPlan));
        worksheet.getCell('E30').value = selectedOptionsString;
        worksheet.getCell('C35').value = maxdiffProgramming;
        worksheet.getCell('C37').value = numberOfAttributes;
        worksheet.getCell('C42').value = jaccards;
        worksheet.getCell('C43').value = regression;
        worksheet.getCell('C44').value = krushal;
        worksheet.getCell('C45').value = shapely;
        worksheet.getCell('C46').value = correlation;
        worksheet.getCell('C47').value = chaid;
        worksheet.getCell('C49').value = cluster;
        worksheet.getCell('C50').value = factor;
        worksheet.getCell('C51').value = bIP;
        worksheet.getCell('F42').value = dDV;
        worksheet.getCell('F44').value = dIV;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `4SiGHT_Analysis Warning Note_${projectName}.xlsx`);
        navigate('/');
    }
    else{
        setShowAlert3(true);
    }
};

        const handleNext = () => {
            if (maxdiffProgramming && jaccards && shapely && correlation && chaid && cluster && factor && bIP &&
                regression && krushal && dDV && dIV && numberOfAttributes && submissionDate && jobNumber &&
                 projectName && projectType && researcherCharge && researcherCost &&
                finalQuestionnaire &&  scirptingNeeded && sampleSize && dataCollection  && 
                 complexLogic
                && dataCollection && haveS2CSub && firstCutDate && dataWeightingRequired && sigTesting
                 && finalOutput && weightingMatrix && statementFF && 
                spssOutput && quotaDetails && 
            expectedFieldDate && analysisPlan && (option1 || option2 || option3 || option4)
            ) {
                navigate('/conclusion');
                setShowAlert3(false);
            } else {
                setShowAlert3(true);
            }
        };

        const handleJob = (e) => {
            const value = e.target.value;
            const regex = /^[A-Za-z]{0,2}$|^[A-Za-z]{2}\d*$/;

            if (regex.test(value)) {
                setJobNumber(value);
            } else {
            }
        };

        // useEffect(() => {
        //     setSubmissionDate(new Date());
        //     setJobNumber('');
        //     setProjectName('');
        //     setProjectType('');
        //     setResearcherCharge('');
        //     setResearcherCost('');
        //     setFinalQuestionnaire('');
        //     setFinalQuestionnaireChanged(false);
        //     setScirptingNeeded('');
        //     setComplexLogic('');
        //     setSampleSize('');
        //     setDataCollection('');
        //     setHaveS2CSub('');
        //     setQuota('');
        //     setQuotaDetails('');
        //     setQuotaDetailsChanged(false);
        //     setExpectedFieldDate('');
        //     setExpectedFieldDateChanged(false);
        //     setAnalysisPlan('');
        //     setAnalysisPlanChanged(false);
        //     setFirstCutDate('');
        //     setFirstCutDateChanged(false);
        //     setDataWeightingRequired('');
        //     setSigTesting('');
        //     setSpssOutput('');
        //     setFinalOutput('');
        //     setFinalOutputChanged(false);
        //     setWeightingMatrix('');
        //     setWeightingMatrixChanged(false);
        //     setOption1(false);
        //     setOption2(false);
        //     setOption3(false);
        //     setOption4(false);
        //     setStatementFF('');
        //     setMaxdiffProgramming('');
        //     setJaccards('');
        //     setRegression('');
        //     setKrushal('');
        //     setShapely('');
        //     setCorrelation('');
        //     setChaid('');
        //     setCluster('');
        //     setFactor('');
        //     setBIP('');
        //     setDDV('');
        //     setDIV('');
        //     setNumberOfAttributes('');
        // }, []);
   
    return (
        <div>
        {/* <div style={{width:'100%', color:'white', backgroundColor:'#7fa9bb', padding:'10px'}}>
        <h4 style={{display:'flex', justifyContent:'center', alignItems:'center', 
         fontFamily: 'Roboto, sans-serif'
         }}>Data Processing - Analysis Specification</h4>
        </div> */}
        <br />
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
        {showAlert && (
            <Alert variant='danger' onClose={() => setShowAlert(false)} dismissible>
                Please Fill Every Field
            </Alert>
        )}
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Submission Date:</Form.Label>
            <Col sm="6">
                <DatePicker
                    selected={submissionDate}
                    onChange={(date) => setSubmissionDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                />
            </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Job Number:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={jobNumber}
            onChange={handleJob}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Project Name:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Project Type:</Form.Label>
            {/* <Form.Label column sm="7" style={{color:'#8EA7E9'}}>Project Type:</Form.Label> */}
            <Col sm="6">
            <Form.Control 
            type="text"
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Researcher In Charge:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={researcherCharge}
            onChange={(e) => setResearcherCharge(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Researcher Cost Center(KSA/UAE):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={researcherCost}
            onChange={(e) => setResearcherCost(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>KSA/UAE</option>
             <option value = "KSA">KSA</option>
             <option value = "UAE">UAE</option>
             </Form.Control>
             </Col>
        </Form.Group>
        </Col>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Final Questionnaire Will Be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={finalQuestionnaire}
                    onChange={handleFinalQuestionnaireChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Scripting Needed:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={scirptingNeeded}
            onChange={(e) => setScirptingNeeded(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>If Yes, does it have any Complex Logic*</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={complexLogic}
            onChange={(e) => setComplexLogic(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sample Size:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={sampleSize}
            onChange={handleSampleSizeChange}
            style={{width:'100%',backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Data Collection Method(CAPI/CAWI):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={dataCollection}
            onChange={(e) => setDataCollection(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>CAPI/CAWI</option>
             <option value = "CAPI">CAPI</option>
             <option value = "CAWI">CAWI</option>
             </Form.Control>
             </Col>
        </Form.Group>
        {/* <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Have S2C Subscription:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={haveS2CSub}
            onChange={(e) => setHaveS2CSub(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group> */}
        </Col>
        </Row>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
        <Button className='awnB' onClick={handleNext1}>
            Next
        </Button>
        </div>
        </Form>
        </div>
        </div>
        <br />
        {showNext && (
        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
        {showAlert2 && (
            <Alert variant='danger' onClose={() => setShowAlert2(false)} dismissible>
                Please Fill Every Field
            </Alert>
        )}
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Have S2C Subscription:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={haveS2CSub}
            onChange={(e) => setHaveS2CSub(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Quota:</Form.Label>
            {/* <Form.Label column sm="7" style={{color:'#D862BC','#5e72e4'}}>Quota:</Form.Label> */}
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={quota}
            onChange={(e) => setQuota(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>
            Quota/Launch Details will be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={quotaDetails}
                    onChange={handleQuotaDetailsChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    disabled={quota !== "Yes"}
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>
            Expected Field Work Completion Date:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={expectedFieldDate}
                    onChange={handleExpectedFieldChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Analysis Plan/Top Break will be Provided On:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={analysisPlan}
                    onChange={handleAnalysisChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>First Cut/Mid Sample Date:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={firstCutDate}
                    onChange={handleFirstCutChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        </Col>
        <Col md ="6">
        <Form.Group as={Row} style={{marginBottom:'10px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Do you need statement fixed format (if applicable)</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={statementFF}
            onChange={(e) => setStatementFF(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sig Testing:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={sigTesting}
            onChange={(e) => setSigTesting(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group> 
        <Form.Group style={{marginBottom:'20px'}}>
        <Row>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Sig Testing Check:</Form.Label>
            <Col sm="6" style={{padding:'2px'}}>
            <Row>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="95%"
                    name="option1"
                    checked={option1}
                    onChange={(e) => setOption1(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="90%"
                    name="option2"
                    checked={option2}
                    onChange={(e) => setOption2(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="80%"
                    name="option3"
                    checked={option3}
                    onChange={(e) => setOption3(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
                <Col>
                <Form.Check
                    type="checkbox"
                    label="67%"
                    name="option4"
                    checked={option4}
                    onChange={(e) => setOption4(e.target.checked)}
                    disabled={sigTesting !== "Yes"}
                />
                </Col>
            </Row>
            </Col>
        </Row>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>SPSS OUTPUT:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as = "select"
            value={spssOutput}
            onChange={(e) => setSpssOutput(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Final Output (Date):</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={finalOutput}
                    onChange={handleFinalOutputChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Data Weighting Required:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={dataWeightingRequired}
            onChange={(e) => setDataWeightingRequired(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Weighting Matrix Will be Provided on:</Form.Label>
            <Col sm="6">
             <DatePicker
                    selected={weightingMatrix}
                    onChange={handleWeightingMatrixChange}
                    dateFormat="dd/MM/yyyy"
                    className="custom-date-picker form-control"
                    disabled={dataWeightingRequired !== "Yes"}
                    onKeyDown={(e) => e.preventDefault()} 
                />
             </Col>
        </Form.Group>
        </Col>
        </Row>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
        <Button className='awnB'  onClick={handleNext2}>
            Next
        </Button>
        </div>
        </Form>
        </div>
        </div>
        </div>
    )}
        <br />
        {showNext2 && (
        <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div className="d-flex flex-column justify-content-center p-4 custom-class" style={{
        backgroundColor: 'white', backdropFilter: 'blur(15px)', color:'black', 
        border:'1px solid lightgrey', width:'95%'}}>
        {showAlert3 && (
            <Alert variant='danger' onClose={() => setShowAlert3(false)} dismissible>
                Please Fill Every Field
            </Alert>
        )}
        <Form>
        <Row>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>MAXDIFF Programming:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={maxdiffProgramming}
            onChange={(e) => setMaxdiffProgramming(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
            // style={{width:'100%', backgroundColor:'#A0DEFF'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Number of Attributes:</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={numberOfAttributes}
            onChange={handleNoaChange}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
            disabled={maxdiffProgramming !== "Yes"}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Jaccards:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={jaccards}
            onChange={(e) => setJaccards(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Regression (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={regression}
            onChange={(e) => setRegression(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Krushal (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={krushal}
            onChange={(e) => setKrushal(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
            <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Shapely (Importance Score):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={shapely}
            onChange={(e) => setShapely(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Correlation (Partial):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={correlation}
            onChange={(e) => setCorrelation(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        </Col>
        <Col md = "6">
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>CHAID:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={chaid}
            onChange={(e) => setChaid(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Cluster:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={cluster}
            onChange={(e) => setCluster(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Factor:</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={factor}
            onChange={(e) => setFactor(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black'
            ,  fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>BIP (Brand Image Profile):</Form.Label>
            <Col sm="6">
            <Form.Control 
            as="select"
            value={bIP }
            onChange={(e) => setBIP (e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             >
             <option value hidden>Yes/No</option>
             <option value = "Yes">Yes</option>
             <option value = "No">No</option>
             </Form.Control>
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black', 
            fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Define Dependant Variable(If multiple - separate it by commas):</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={dDV}
            onChange={(e) => setDDV(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <Form.Group as={Row} style={{marginBottom:'20px'}}>
            <Form.Label column sm="6" style={{color:'black',
              fontFamily: 'Roboto, sans-serif', backgroundColor:'#e8e3ec', borderRadius:'5px'}}>Define Independent Variable(If multiple - separate it by commas):</Form.Label>
            <Col sm="6">
            <Form.Control 
            type="text"
            value={dIV}
            onChange={(e) => setDIV(e.target.value)}
            style={{width:'100%', backgroundColor:'#F1F1F1'}}
             />
             </Col>
        </Form.Group>
        <br />
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <Button className='awnB'  onClick={handleDownload}>
                    Submit</Button>
            </div>
            </Form>
                    {/* <div>
                    <h6 style={{color:'red', marginRight:'10px'}}>
                    *Click the Button to Download your Responses in Excel Format</h6>
                    </div>
                    <div>
                <button class="button" type="button" onClick={handleDownload}>
                            <span class="button__text">Download</span>
                            <span class="button__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" class="svg">
                                    <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                                    <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                                    <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                                </svg>
                            </span>
                        </button>
                        </div> */}
                </div>
            </div>
        <br />
        </div>
    )}
        </div>
    );
}

export default Awn1;
