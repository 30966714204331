import React, { useState, useEffect } from 'react';
import './Navibar.css';
import Navibar2 from './Navibar2.js';
import Segments from './Segments.js';

const NaviBar = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [inputValues1, setInputValues] = useState(['', '', '', '', '', '']);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const items = [
    'UNIT', 'YEAR', 'MONTH', 'PROJECT NAME', 'JOB NUMBER', 'CLIENT'
  ];
  const unitOptions = ['4SIGHT UAE', '4SIGHT KSA'];

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    if (items[index] === 'YEAR' && isNaN(newValue)) {
      return;
    }
    if (items[index] === 'YEAR' && newValue.length > 4) {
      return;
    }
    if (items[index] === 'JOB NUMBER') {
      if (newValue.length <= 2) {
        if (!/^[A-Za-z]*$/.test(newValue)) {
          return;
        }
      } else {
        if (!/^\d+$/.test(newValue.slice(2))) {
          return;
        }
      }
    }

    const newInputValues = [...inputValues1];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
  };

  useEffect(() => {
    if (inputValues1.every(value => value.trim() !== '')) {
      const data = inputValues1.map((value, index) => `${items[index]}: ${value}`);
      sendToBackend(data);
    }
  }, [inputValues1]);

  const sendToBackend = (data) => {
    fetch('your-backend-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to send data to backend');
      }
      console.log('Data successfully sent to backend');
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div>
        {/* <div style={{display:'flex', justifyContent:'center', backgroundColor:'#276B', color:'white'}}>
        <h3 style={{fontFamily: 'Roboto, sans-serif'}}><strong>Job Tracker</strong></h3>
    </div> */}
      <div className="navbar">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {activeIndex === index ? (
              <div className="form-controll">
                {item === 'MONTH' ? (
                  <select
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                    style={{padding:'5px'}}
                  >
                    <option value="" hidden>Select Month</option>
                    {months.map((month, i) => (
                      <option key={i} value={month}>{month}</option>
                    ))}
                  </select>
                ) : item === 'UNIT' ? (
                  <select
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                    style={{padding:'5px'}}
                  >
                    <option value="" hidden>Select Unit</option>
                    {unitOptions.map((unit, i) => (
                      <option key={i} value={unit}>{unit}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-controll form-inputt"
                    value={inputValues1[index]}
                    onChange={(e) => handleInputChange(e, index)}
                    onBlur={() => setActiveIndex(-1)}
                    autoFocus
                    required
                  />
                )}
                <label>
                  {item.split('').map((char, i) => (
                    <span key={i} style={{ transitionDelay: `${i * 5}ms` }}>
                      {char}
                    </span>
                  ))}
                </label>
              </div>
            ) : (
              <button
                onClick={() => handleItemClick(index)}
                className="btn btn-outline-secondary sidebar-button"
              >
                {inputValues1[index] ? `${item}: ${inputValues1[index]}` : `${item}:`}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
      <Segments inputValues1={inputValues1} />
    </div>
  );
};

export default NaviBar;
