import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'; // Import the edit icon
import { useNavigate } from 'react-router-dom';

const ProjectList = () => {
  const [projectNames, setProjectNames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectNames = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/project-names/');
        const data = await response.json();
        setProjectNames(data);
      } catch (error) {
        console.error('Error fetching project names:', error);
      }
    };

    fetchProjectNames();
  }, []);

  const handleEdit = (name) => {
    console.log(`Edit ${name}`);
  };

  const handleShowSegments = () => {
    navigate('/navibar');
  };

  return (
    <div>
    <br />
    <div style={{width:'80%', margin:'auto'}}>
      <Table bordered hover style={{border:'1px solid grey', marginTop:'100px'}}>
        <thead>
          <tr>
            <td style={{color:'green', padding:'20px'}}>PROJECT NAMES:</td>
            <td style={{color:'green', padding:'20px'}}>EDIT</td>
          </tr>
        </thead>
        <tbody>
          {projectNames.map((name, index) => (
            <tr key={index}>
              <td>{name}</td>
              <td>
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{ cursor: 'pointer', color:'grey' }}
                  onClick={() => handleEdit(name)} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    <Button className='fontButton' onClick={handleShowSegments}>Create New !</Button>
    </div>
    </div>
  );
};

export default ProjectList;
