import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

function DisplayTable() {
  return (
    <div>
    <div style={{marginTop:'200px'}}>
      <div style={{margin:'auto', border:'1px solid lightgrey', borderRadius:'10px', padding:'20px', width:'50%',
      backgroundColor:'white'}}>
        <Form>
            <Form.Group as={Row} controlId='projectName'>
            <Row>
            <Col sm={3} style={{margin:'auto'}}>
                <Form.Label>Project Name:</Form.Label>
                </Col>
                <Col sm={9} style={{margin:'auto'}}>
                <Form.Control
                type='text'
                placeholder='Enter Your Project Name'
                style={{backgroundColor:'#F1F1F1'}}
                />
                </Col>
                </Row>
            </Form.Group>
        </Form>
        <div style={{display:'flex', justifyContent:'center', marginTop:'50px'}}>
        <Button>
            Submit
        </Button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default DisplayTable
