import React, { useState, useEffect } from 'react';
import './Segments.css';
import Navibar2 from './Navibar2';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Dropdown = ({ options, onSelect, selectedIndex }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown5">
      <select
        className="custom-dropdown5-toggle form-control1"
        value={options[selectedIndex]}
        onChange={(e) => handleSelect(e.target.value)}
        onBlur={() => setIsOpen(false)}
        autoFocus
        required
        style={{width:'180px'}}
      >
        <option value="" hidden></option>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

const Segments = ({ inputValues1 }) => {
  const [segmentCount, setSegmentCount] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [dropdownOptions, setDropdownOptions] = useState({
    'SURVEY PLATFORM': ['S2C', 'STG', 'FORSTA'],
    'DP PLATFORM': ['SPSS', 'DIMENSIONS', 'Q RESEARCH TOOL', 'QUANTUM'],
    'PROJECT CLASSIFICATION': ['CLASS A', 'CLASS B', 'CLASS C', 'CLASS D'],
    'RESEARCH GROUP HEAD': ['ELIAS', 'KUNAL', 'RAHUL', 'MANOJ IYER', 'ASHISH'],
    'CONTACT PERSON IN RESEARCH': ['ANANYA', 'PULKIT', 'PRIYA', 'SARANYA', 'MANOJ KUMAR', 'ARUNA','VINIVAA', 'SONAL',
      'CAROLINE MAINA', 'KRATI'
    ],
    'ANALYSIS GROUP HEAD': ['RAMAMOORTHY', 'SATHISH KUMAR'],
    'CONTACT PERSON IN ANALYSIS': ['ANURADHA', 'VARUN', 'SRIVENDHAN', 'RAGHU RAM', 'SARAVANA KUMAR'],
    'ADVANCED ANALYTICS': ['MAXDIFF', 'DRIVER ANALYSIS', 'CORRELATION', 'JACCARD', 'CHI SQUARE'],
    'CHECK LIST FOLLOWED':['YES', 'NO'],
    'FEEDBACK SURVEY DONE':['YES', 'NO'], 
    'DATA CORRECTION DONE':['YES', 'NO'],
    'COMPLETED MONTH': [
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 
    'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ],
  'NORMS DONE':['YES', 'NO']
  });
  const [inputValues, setInputValues] = useState([]);
  const items = [
    'SEGMENT NAME',
    'ACTUAL SAMPLE SIZE',
    'ACHIEVED SAMPLE SIZE',
    'FINAL PROCESSED SAMPLES',
    'RESEARCH GROUP HEAD',
    'CONTACT PERSON IN RESEARCH',
    'ANALYSIS GROUP HEAD',
    'CONTACT PERSON IN ANALYSIS',
    'DATE OF AWN',
    'DATE OF R-AWN1',
    'DATE OF R-AWN2',
    'DATE OF R-AWN3',
    'QUESTIONNAIRE RECEIVED DATE',
    'AP RECEIVED DATE',
    'FW START DATE',
    'FW END DATE',
    'ADVANCED ANALYTICS',
    'EXPECTED DATE OF OUTPUT',
    'ACTUAL FINAL OUTPUT',
    'TOP LINES',
    'OE CODING',
    'OE COSTING',
    'NUMBER OF RESPONSE',
    'DATA CORRECTION DONE',
    'NORMS DONE',
    'SURVEY PLATFORM',
    'DP PLATFORM',
    'PROJECT CLASSIFICATION',
    'STATUS',
    'LOI',
    'CHECK LIST FOLLOWED',
    'FEEDBACK SURVEY DONE',
    'COMPLETED MONTH',
    'ARCHIVE TAKEN',
    'REMARKS'
  ];

  const navigate = useNavigate();

  useEffect(() => {
    setInputValues(Array.from({ length: segmentCount }, () => ({})));
  }, [segmentCount]);

  const [selectedDropdownValues, setSelectedDropdownValues] = useState([]);

  useEffect(() => {
    setSelectedDropdownValues(Array.from({ length: segmentCount }, () => ({})));
  }, [currentSegment, segmentCount]);

  const clearInputValues = () => {
    const newInputValues = [...inputValues];
    newInputValues[currentSegment] = {};
    setInputValues(newInputValues);
  };

  const handleSegmentCountChange = (newCount) => {
    setSegmentCount(newCount);
    setCurrentSegment(0);
    setSubmitted(false);
  };

  const handleNextClick = () => {
    const currentSegmentInputs = [
      'EXPECTED DATE OF OUTPUT',
      'ACTUAL FINAL OUTPUT',
      'SEGMENT NAME',
      'COMPLETED MONTH',
      'DATA CORRECTION DONE',
      'STATUS',
      'RESEARCH GROUP HEAD',
    'CONTACT PERSON IN RESEARCH',
    'ANALYSIS GROUP HEAD',
    'CONTACT PERSON IN ANALYSIS',
'DATA CORRECTION DONE',
'CHECK LIST FOLLOWED',
    'FEEDBACK SURVEY DONE',
    ];
  
    const oeFieldsFilled = inputValues[currentSegment]['OE CODING'] && inputValues[currentSegment]['OE COSTING'];
  
    if (oeFieldsFilled) {
      currentSegmentInputs.push('NUMBER OF RESPONSE');
    }
  
    let allInputsFilled;
  
    if (oeFieldsFilled) {
      allInputsFilled = currentSegmentInputs.every((item) => {
        if ([
          'SURVEY PLATFORM', 
          'DP PLATFORM', 
          'PROJECT CLASSIFICATION', 
          'RESEARCH GROUP HEAD', 
          'CONTACT PERSON IN RESEARCH', 
          'ANALYSIS GROUP HEAD', 
          'CONTACT PERSON IN ANALYSIS', 
          'ADVANCED ANALYTICS',
          'CHECK LIST FOLLOWED', 
          'FEEDBACK SURVEY DONE', 
          'DATA CORRECTION DONE', 
          'COMPLETED MONTH',
          'NORMS DONE'
        ].includes(item)) {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== '';
        } else if (typeof inputValues[currentSegment][item] === 'string') {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item].trim() !== '';
        } else {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== null;
        }
      });
    } else {
      allInputsFilled = currentSegmentInputs.filter(item => item !== 'NUMBER OF RESPONSE').every((item) => {
        if ([
          'SURVEY PLATFORM', 
          'DP PLATFORM', 
          'PROJECT CLASSIFICATION', 
          'RESEARCH GROUP HEAD', 
          'CONTACT PERSON IN RESEARCH', 
          'ANALYSIS GROUP HEAD', 
          'CONTACT PERSON IN ANALYSIS', 
          'ADVANCED ANALYTICS',
          'CHECK LIST FOLLOWED', 
          'FEEDBACK SURVEY DONE', 
          'DATA CORRECTION DONE', 
          'COMPLETED MONTH',
          'NORMS DONE'
        ].includes(item)) {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== '';
        } else if (typeof inputValues[currentSegment][item] === 'string') {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item].trim() !== '';
        } else {
          return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== null;
        }
      });
    }
  
    if (allInputsFilled) {
      const formattedInputValues = { ...inputValues[currentSegment] };      
      const dateFields = [
        'DATE OF AWN',
        'DATE OF R-AWN1',
        'DATE OF R-AWN2',
        'DATE OF R-AWN3',
        'QUESTIONNAIRE RECEIVED DATE',
        'AP RECEIVED DATE',
        'FW START DATE',
        'FW END DATE',
        'EXPECTED DATE OF OUTPUT',
        'ACTUAL FINAL OUTPUT'
      ];
  
      dateFields.forEach(field => {
        if (formattedInputValues[field] instanceof Date) {
          formattedInputValues[field] = formattedInputValues[field].toLocaleDateString
          ('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
      });  
      localStorage.setItem(`segment_${currentSegment}`, JSON.stringify(formattedInputValues));
      setCurrentSegment((prevSegment) => prevSegment + 1);
      clearInputValues();
    } else {
      alert('Please Fill the  Required Fields');
    }
  };
  
  const clearStoredData = () => {
    for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
      localStorage.removeItem(`segment_${segmentIndex}`);
    }
  };
  window.onbeforeunload = clearStoredData;

  const handleDropdownSelect = (item, option) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[currentSegment] = { ...updatedInputValues[currentSegment], [item]: option };
    setInputValues(updatedInputValues);
  };

  const handleInputChange = (event, item) => {
    const { value } = event.target;
    const updatedInputValues = [...inputValues];

    updatedInputValues[currentSegment] = { ...updatedInputValues[currentSegment], [item]: value };
    setInputValues(updatedInputValues);
  };
  

  const handleSubmission = () => {
    setSubmitted(true);
  }

  const handleSubmission1 = () => {
    const currentSegmentInputs = ['EXPECTED DATE OF OUTPUT',
      'ACTUAL FINAL OUTPUT',
      'SEGMENT NAME',
      'COMPLETED MONTH',
      'STATUS',
      'RESEARCH GROUP HEAD',
      'CONTACT PERSON IN RESEARCH',
      'ANALYSIS GROUP HEAD',
      'CONTACT PERSON IN ANALYSIS',
      'DATA CORRECTION DONE',
      'CHECK LIST FOLLOWED',
      'FEEDBACK SURVEY DONE',];
  
    const oeFieldsFilled = inputValues[currentSegment]['OE CODING'] && inputValues[currentSegment]['OE COSTING'];
    if (oeFieldsFilled) {
      currentSegmentInputs.push('NUMBER OF RESPONSE');
    }
  
    const allInputsFilled = currentSegmentInputs.every((item) => {
      if (['SURVEY PLATFORM', 'DP PLATFORM', 'PROJECT CLASSIFICATION', 'RESEARCH GROUP HEAD', 
        'CONTACT PERSON IN RESEARCH', 'ANALYSIS GROUP HEAD', 'CONTACT PERSON IN ANALYSIS', 'ADVANCED ANALYTICS',
        'CHECK LIST FOLLOWED', 'FEEDBACK SURVEY DONE', 'DATA CORRECTION DONE', 'COMPLETED MONTH', 'NORMS DONE'
      ].includes(item)) {
        return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== '';
      } else if (typeof inputValues[currentSegment][item] === 'string') {
        return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item].trim() !== '';
      } else {
        return inputValues[currentSegment][item] !== undefined && inputValues[currentSegment][item] !== null;
      }
    });
  
    if (allInputsFilled) {
      const dataToSend = [];
      dataToSend.push({
        Unit: inputValues1[0],
        Year: inputValues1[1],
        Month: inputValues1[2],
        ProjectName: inputValues1[3],
        JobNumber: inputValues1[4],
        Client: inputValues1[5],
        segmentCount: segmentCount
      });

    navigate('/conclusion');

    for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
      const storedSegmentData = localStorage.getItem(`segment_${segmentIndex}`);
      if (storedSegmentData) {
        const segmentValues = JSON.parse(storedSegmentData);
        Object.entries(segmentValues).forEach(([item, value]) => {
          if (Array.isArray(value)) {
            dataToSend.push({
              SegmentNumber: segmentIndex + 1,
              item,
              value: dropdownOptions[item][value],
              selectedDropdownValue: value
            });
          } else if (value instanceof Date) {
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value: value.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })
            });
          } else {
            dataToSend.push({
              segmentNumber: segmentIndex + 1,
              item,
              value
            });
          }
        });
      }
    }

    if (inputValues[currentSegment]) {
      Object.entries(inputValues[currentSegment]).forEach(([item, value]) => {
        if (Array.isArray(value)) {
          dataToSend.push({
            segmentNumber: currentSegment + 1,
            item,
            value: dropdownOptions[item][value],
            selectedDropdownValue: value
          });
        } else if (value instanceof Date) {
          dataToSend.push({
            segmentNumber: currentSegment + 1,
            item,
            value: value.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })
          });
        } else {
          dataToSend.push({
            segmentNumber: currentSegment + 1,
            item,
            value
          });
        }
      });
    }

    fetch('http://localhost:8000/api/segments/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
    .then(response => {
      if (response.ok) {
        console.log('Data sent successfully');
        for (let segmentIndex = 0; segmentIndex < segmentCount; segmentIndex++) {
          localStorage.removeItem(`segment_${segmentIndex}`);
        }
      } else {
        console.error('Error:', response.statusText);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  } else {
    alert('Please Fill the  Required Fields');
  }
};

  return (
    <div>
      <Navibar2 segmentCount={segmentCount} onSegmentCountChange={handleSegmentCountChange}
       onSubmit={handleSubmission} />
      {submitted && (
        <div>
          {Array.from({ length: segmentCount }).map((_, segmentIndex) => (
            <div key={segmentIndex} className={`segment-content ${segmentIndex === currentSegment ?
             'active1' : ''}`} style={{ display: segmentIndex === currentSegment ? 'block' : 'none' }}>
              <div className="card2">
                <h5 style={{display:'flex', justifyContent:'center', fontFamily: 'Roboto, sans-serif'}}>
                Segment {segmentIndex + 1}:</h5>
                <hr />
                <div className="variable-columns2">
                {[...Array(Math.ceil(items.length / 10))].map((_, columnIndex) => (
                  <ul key={columnIndex} className="variable-list2">
                    {items.slice(columnIndex * 12, (columnIndex + 1) * 12).map((item, index) => (
                      <li key={index}>
                        <label style={{ fontSize: '0.7rem' }}>{item}:</label>
                        <div style={{ position: 'relative' }}>
                          {item === 'DATE OF AWN' || item === 'DATE OF R-AWN1' || item === 'DATE OF R-AWN2'
                          || item === 'DATE OF R-AWN3' || item === 'QUESTIONNAIRE RECEIVED DATE' 
                          || item === 'AP RECEIVED DATE' || item === 'FW START DATE'
                          || item === 'FW END DATE' || item === 'EXPECTED DATE OF OUTPUT' ||
                           item === 'ACTUAL FINAL OUTPUT'  ? (
                            <DatePicker
                              selected={inputValues[currentSegment][item]}
                              onChange={(date) => handleInputChange({ target: { value: date } }, item)}
                              onKeyDown={(e) => e.preventDefault()} 
                              dateFormat="dd/MM/yyyy"
                            />
                          ) : ['SURVEY PLATFORM', 'DP PLATFORM', 'PROJECT CLASSIFICATION', 'RESEARCH GROUP HEAD',
                            'CONTACT PERSON IN RESEARCH', 'ANALYSIS GROUP HEAD', 'CONTACT PERSON IN ANALYSIS',
                            'ADVANCED ANALYTICS', 'CHECK LIST FOLLOWED',
                            'FEEDBACK SURVEY DONE', 'DATA CORRECTION DONE', 'COMPLETED MONTH', 'NORMS DONE']
                            .includes(item) ? (
                                <Dropdown
                                  options={dropdownOptions[item]}
                                  onSelect={(option) => handleDropdownSelect(item, option)}
                                  selectedIndex={Object.keys(selectedDropdownValues[currentSegment] ||
                                   {}).includes(item) ? dropdownOptions[item].indexOf(selectedDropdownValues
                                   [currentSegment][item]) : -1}
                                />
                              ) : (
                            <input
                              type="text"
                              value={inputValues[currentSegment][item] || ''}
                              onChange={(event) => handleInputChange(event, item)}
                              data-index={columnIndex * 8 + index}
                            />
                          )}
                        </div>
                      </li>
                      ))}
                    </ul>
                  ))}
                </div>
                <div style={{display:'flex', justifyContent:'center'}}>
                {segmentIndex < segmentCount - 1 && (
                  <button
                    className="next-button3"
                    onClick={handleNextClick}
                    disabled={!Object.keys(inputValues[currentSegment] ||
                     {}).every((key) => inputValues[currentSegment][key] !== '')}
                  >
                    Next
                  </button>
                )}
                {segmentIndex === segmentCount - 1 && (
                  <button
                    className="submit-button4"
                    onClick={handleSubmission1}
                    disabled={!Object.keys(inputValues[currentSegment] ||
                     {}).every((key) => inputValues[currentSegment][key] !== '')}
                  >
                    Submit
                  </button>
                )}
              </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <br />
    </div>
  );
};

export default Segments;
